<script lang="ts" setup>
import { navigateTo } from "#imports";

const { t } = useT();

const isGuest = useIsGuest();
const { open } = useAppModals();
const loginGuard = useLoginGuard();
const { weeklyCashbackData, durationLeftDisplay, claimIsActive, claimWeeklyReward } = useStatus();

const btnText = computed(() => {
	if (isGuest.value) {
		return "Level Up Your Game!";
	}

	if (!weeklyCashbackData.value?.currentReward) {
		return "Play to Level Up!";
	}

	return "Claim";
});

const handleClaimClick = () => {
	loginGuard({
		success: () => {
			if (!weeklyCashbackData.value?.currentReward) {
				navigateTo("/issues/all-games/");
				return;
			}
			if (!claimIsActive.value) {
				return;
			}
			claimWeeklyReward();
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const hasNextReward = computed(
	() => weeklyCashbackData?.value?.currentReward !== weeklyCashbackData?.value?.nextReward
);
</script>

<template>
	<ACard
		class="vip"
		width="584px"
		height="760px"
		:imgAttrs="{
			width: 584,
			height: 761,
			src: '/nuxt-img/vip/promo/bg.png',
			alt: 'vip-club-bg',
			format: 'webp',
			loading: 'lazy'
		}"
		alt="vip-club-bg"
		@click="handleClaimClick"
	>
		<template #topLine>
			<AText class="countdown" :modifiers="['uppercase', 'center']" as="div">
				<template v-if="isGuest">
					<AText as="div" :modifiers="['uppercase']">
						<i18n-t keypath="Up to {key} coin back">
							<template #key>
								<span class="text-primary-400">{{ t("20%") }}</span>
							</template>
						</i18n-t>
					</AText>
				</template>
				<template v-else-if="!weeklyCashbackData?.currentReward">
					<AText as="div" :modifiers="['uppercase']">
						<i18n-t keypath="Reach {key} to unlock">
							<template #key>
								<span class="text-primary-400">{{ t("level 2") }}</span>
							</template>
						</i18n-t>
					</AText>
				</template>

				<AText v-else as="div" :modifiers="['uppercase']">
					<i18n-t v-if="!claimIsActive" keypath="Claim in {key}">
						<template #key>
							<AText :modifiers="['uppercase']" class="text-primary-400">{{ durationLeftDisplay }}</AText>
						</template>
					</i18n-t>
					<template v-else>
						{{ t("Reward available!") }}
					</template>
				</AText>
			</AText>
		</template>
		<template #top>
			<MPromotionHeadlineFont>{{ t("vip club") }}</MPromotionHeadlineFont>

			<AText v-if="isGuest" class="text" :size="20" :modifiers="['bold', 'center']">
				{{ t("Experience the Height of Luxury and Rewards") }}
			</AText>
			<template v-else>
				<AText :size="12" :modifiers="['secondary', 'semibold', 'center']" as="div" class="subtitle">
					{{ t("Your Weekly Coins Back") }}
				</AText>
				<div class="weekly-reward">
					<div class="weekly-reward-item">
						<AText class="text-primary-400" :size="28" :modifiers="['bold', 'center']">
							{{ weeklyCashbackData?.currentReward }}%
						</AText>
						<AText class="text-neutral-50" :size="10" :modifiers="['bold', 'center', 'uppercase']">
							{{ t("Current") }}
						</AText>
					</div>
					<ASvg v-if="weeklyCashbackData?.nextReward && hasNextReward" name="vip-club/arrow-reward" class="icon" />
					<div v-if="weeklyCashbackData?.nextReward && hasNextReward" class="weekly-reward-item next">
						<AText class="text-neutral-50" :size="28" :modifiers="['bold', 'center']">
							{{ weeklyCashbackData.nextReward }}%
						</AText>
						<AText class="text-neutral-50" :size="10" :modifiers="['bold', 'center', 'uppercase', 'nowrap']">
							{{ t("next status") }}
						</AText>
					</div>
				</div>
			</template>
		</template>
		<template #bottom>
			<NuxtImg
				src="/nuxt-img/vip/promo/art.png"
				format="avif"
				width="438"
				height="294"
				loading="lazy"
				alt="vip-promo-img"
				class="image"
			/>
			<AButton
				variant="primary"
				:modifiers="[!isGuest && !claimIsActive && weeklyCashbackData?.currentReward ? 'disabled' : '']"
				size="xl"
				class="foot"
				@click="handleClaimClick"
			>
				<AText :size="18" :modifiers="['medium']">
					{{ t(btnText) }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.vip {
	--a-card-default-top-gap: 8px;

	width: 292px;
	height: 380px;
}

.text {
	text-shadow:
		0 10px 15px rgba(var(--gray-950-rgb), 0.1),
		0 4px 6px rgba(var(--gray-950-rgb), 0.05);
	line-height: 26px;
}

.subtitle {
	color: var(--neutral-50);
	line-height: 18px;
}

.image {
	width: 292px;
	height: 196px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.weekly-reward {
	width: 100%;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	background: linear-gradient(90deg, rgba(188, 80, 255, 0) 0%, #bc50ff 46.53%, rgba(188, 80, 255, 0) 100%);
}

.icon {
	font-size: 48px;
	height: 24px;

	&:deep(svg) {
		height: 24px;
	}
}

.weekly-reward-item {
	display: flex;
	flex-direction: column;

	&.next {
		opacity: 0.7;
	}
}

.foot {
	width: 100%;
}
</style>
